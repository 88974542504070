import { useEffect, useState } from "react";
import { Col, Card, Button, Form } from "react-bootstrap";
//import { ReactCardFlip } from '../lib/react-card-flip';
import ReactCardFlip from "react-card-flip";
import * as fcl from "@onflow/fcl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './NFTItem.css';

import { ThreeDots } from 'react-loader-spinner';


import Wheel_Metadata from "../data/wheel_metadata.json";
import Helmet_Metadata from "../data/helmet_metadata.json";
import Tire_Metadata from "../data/tire_metadata.json";
import Car_Metadata from "../data/car_metadata.json";
import Genesis_Metadata from "../data/genesis_metadata.json";
import CarClub_Metadata from "../data/carclub_metadata.json";

import { revealCarClub } from "./carclub/revealCarClub";
import { getStakedNFTDetails } from "../Cadence/Scripts/getStakedNFTDetails";
import { stakeDriverz} from "../Cadence/Transactions/stakeDriverz";
import { unstakeDriverz } from "../Cadence/Transactions/unstakeDriverz";

export default function NFTItem(props) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [genesisMetadata, setGenesisMetadata] = useState(null);
  const [carclubMetadata, setCarClubMetadata] = useState(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [isVideo, setIsVideo] = useState(false);

  const [stakedNFTs, setStakedNFTs] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRevealing, setIsRevealing] = useState(false);
  const [refresh, setRefresh] = useState(false);

    // ✅ Fix: Declare backCard and overall at the beginning
    // let backCard = null;
    let overall = 0;

    const fetchStakedNfts = async (address) => {
      try {
        const stakedNFTsRes = await fcl.query({
          cadence: getStakedNFTDetails,
          args: (arg, t) => [arg(address, t.address)]
        });
        console.log("Staked NFT Result: ", stakedNFTsRes);
        setStakedNFTs(stakedNFTsRes)
      } catch (error) {

      }
    }

    const handleUnstakeClick = async () => {
      try {
        setIsProcessing(true)
        const nftId = props.data.nftId;
        let nftType = "";

        // If it's a Car Club NFT, determine the specific type based on ID range
        if (props.data.nftType === "Car Club") {
          if (nftId >= 0 && nftId <= 4700) {
              nftType = "carclubcommon";  
          } else if ((nftId >= 4701 && nftId <= 4985) || (nftId >= 5001 && nftId <= 5525)) {
              nftType = "carclubrare";
          } else if (nftId >= 4986 && nftId <= 5000) {
              nftType = "carclubsponsor";
          } else if (nftId >= 5526 && nftId <= 5555) {
              nftType = "carclublegendary";
          } else {
              console.error("Unrecognized Car Club NFT ID range");
              return;
          }
      } else if (props.data.nftType === "Tires") {
          nftType = "tires";
      } else if (props.data.nftType === "Wheel") {
          nftType = "wheels";
      } else if (props.data.nftType === "Helmet") {
          nftType = "helmets";
      } else if (props.data.nftType === "Car") {
          nftType = "cars";
      } else if (props.data.nftType === "Genesis") {
          nftType = "genesis";
      } else {
          console.error("NFT ID/type does not match expected values");
          return; // Stop execution
      }

      
      const txid = await fcl.mutate({
        cadence: unstakeDriverz,
        args: (arg, t) => [
            arg([nftId], t.Array(t.UInt64)), 
            arg([nftType], t.Array(t.String))
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
    });
        
        await fcl.tx(txid).onceSealed();
        console.log("✅ NFT unstaked Successfully!");

        props.getNFTs();

        setIsProcessing(false);

        setTimeout(() => {
          window.location.reload();
        }, 200); // 1-second delay for any potential data sync
      } catch (error) {
        console.error("❌ Error during unstaking:", error);
        setIsProcessing(false);
      }
    }
  

    const handleStakeClick = async () => {
      try {
        setIsProcessing(true);
          const nftId = props.data.nftId;
          const nftName = props.data.name;
          const thumbnail = props.data.thumbnail;
          let nftType = "";
          let rarity = 0;

          // If it's a Car Club NFT, determine the specific type based on ID range
          if (props.data.nftType === "Car Club") {
              if (nftId >= 0 && nftId <= 4700) {
                  nftType = "carclubcommon";  
              } else if ((nftId >= 4701 && nftId <= 4985) || (nftId >= 5001 && nftId <= 5525)) {
                  nftType = "carclubrare";
              } else if (nftId >= 4986 && nftId <= 5000) {
                  nftType = "carclubsponsor";
              } else if (nftId >= 5526 && nftId <= 5555) {
                  nftType = "carclublegendary";
              } else {
                  console.error("Unrecognized Car Club NFT ID range");
                  return;
              }
          } else if (props.data.nftType === "Tires") {
              nftType = "tires";
          } else if (props.data.nftType === "Wheel") {
              nftType = "wheels";
          } else if (props.data.nftType === "Helmet") {
              nftType = "helmets";
          } else if (props.data.nftType === "Car") {
              nftType = "cars";
          } else if (props.data.nftType === "Genesis") {
              nftType = "genesis";
          } else {
              console.error("NFT ID/type does not match expected values");
              return; // Stop execution
          }

          console.log(`Staking NFT - ID: ${nftId}, Type: ${nftType}, Name: ${nftName}, Rarity: ${rarity}`);

          const txid = await fcl.mutate({
              cadence: stakeDriverz,
              args: (arg, t) => [
                  arg([nftId], t.Array(t.UInt64)), 
                  arg([nftType], t.Array(t.String)), 
                  arg([nftName], t.Array(t.String)),
                  arg([thumbnail], t.Array(t.String))
              ],
              proposer: fcl.currentUser,
              payer: fcl.currentUser,
              authorizations: [fcl.currentUser],
              limit: 999,
          });

          await fcl.tx(txid).onceSealed();
          console.log("✅ NFT Staked Successfully!");

          props.getNFTs();

          setIsProcessing(false);

          setTimeout(() => {
            window.location.reload();
          }, 200); // 1-second delay for any potential data sync
      } catch (error) {
          console.error("❌ Error during staking:", error);
          setIsProcessing(false);
      }
  };



  useEffect(() => {


    if (props.data.nftType === "Car" && props.data.nftId <=45) {
      var temp = props.data.name.split("Car ");
      Car_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);

    } else if (props.data.nftType === "Car" && props.data.nftId > 45 ) {
      setThumbnail("../../assets/PackVideos/Starter_Pack.mp4");
      setIsVideo(true);
    

      
    } else if (props.data.nftType === "Wheel") {
      var temp = props.data.name.split("Steering Wheel ");
      Wheel_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    } else if (props.data.nftType === "Helmet") {
      var temp = props.data.name.split("Helmet ");
      Helmet_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    } else if (props.data.nftType === "Tires") {
      var temp = props.data.name.split("Tire ");
      Tire_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    } else if(props.data.nftType === "Genesis") {
      Genesis_Metadata.map((data) => {
        if (data.revealDisplay.name === props.data.name){
          setGenesisMetadata(data.metadata);
        }
      });     
      // Log the original thumbnail URL
      console.log(props.data.thumbnail);

      // Adjust the thumbnail URL to replace 'ipfs://' with 'ipfs/'
      const adjustedThumbnail = props.data.thumbnail.replace('ipfs://', 'ipfs/');

      // Log the adjusted thumbnail URL
      console.log('Adjusted Thumbnail:', adjustedThumbnail);

      // Set the adjusted thumbnail
      setThumbnail("https://gateway.pinata.cloud/"+adjustedThumbnail);
      setIsVideo(false);
    } else if (props.data.nftType === "Car Club") {
      CarClub_Metadata.map((data) => {
        if (data.Name === props.data.name){
          setCarClubMetadata(data);
        }
      });

      if(props.data.nftId >= 5001 && props.data.nftId <= 5525){
        setThumbnail("https://gateway.pinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%28"+ props.data.nftId +"%29.mp4");
        setIsVideo(true);
      }else{
        setThumbnail(props.data.thumbnail);
        setIsVideo(false);
      }
    }

  }, [refresh]);

  useEffect(() => {
    console.log("Thumbnail:", props.data.thumbnail);
  }, [props.data.thumbnail]);










  const profileClick = (name) => {
    setIsFlipped(!isFlipped);
  };



  const revealClick = async (id) => {
    console.log("Reveal Clicked - ID:", id);
    setIsRevealing(true); // Start the loading spinner
    setIsProcessing(true);
    try {
      const TXid = await fcl.mutate({
        cadence: revealCarClub,
        args: (arg, t) => [
          arg(id, t.UInt64), //NFT ID
        ],
        limit: 9999,
      });
  
      console.log("Transaction ID:", TXid);
  
      // Optionally, subscribe to the transaction status (for logging/debugging)
      fcl.tx(TXid).subscribe((status) => {
        console.log("Transaction status is:", status);
      });
  
      // Wait for the transaction to be sealed
      const result = await fcl.tx(TXid).onceSealed();
  
      console.log("Transaction sealed:", result);
      toast.success("Successfully revealed!");
      props.getNFTs();
      setIsProcessing(false);
      // Wait for 1 second before refreshing the thumbnail
      setTimeout(() => {
        window.location.reload();
      }, 200); // 1-second delay for any potential data sync
  
    } catch (error) {
      console.error("Error in revealClick:", error);
      toast.error("Reveal transaction failed!");
      setIsProcessing(false);

    } finally {
      setIsRevealing(false); // Stop the loading spinner after completion
      setIsProcessing(false);

    }
  };
  
  
  const backCard = (
    <div className="backcard-container">
      {/* 🔥 Mini Image or Video for Trading Card Feel */}
      <div className="collectible-image-container">
        {props.data.nftType === "Car Club" && props.data.nftId >= 5001 && props.data.nftId <= 5525 ? (
          // 🎥 If Car Club is Legendary, show a video
          <video width="100%" height="auto" className="collectible-video" autoPlay loop muted>
            <source src={thumbnail} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          // 🖼️ Otherwise, show an image
          <img src={thumbnail} alt={props.data.name} className="collectible-image" />
        )}
      </div>
  
      {/* 🏆 Card Title */}
      <h5 className="text-center font-style card-title">
        {props.data.name}
      </h5>
  
      {/* 🎖️ Stats & Traits Section - Displays All, Excluding Certain Keys */}
      <div className="traits-container">
        {metadata && (
          metadata
            .filter(data => !["ID", "Name", "Description", "IPFS"].includes(data.trait_type)) // Exclude unwanted keys
            .map((data, index) => (
              <div key={index} className="trait-row">
                <p className="trait-label">{data.trait_type.toUpperCase()}</p>
                <p className="trait-value">{data.value}</p>
              </div>
            ))
        )}
  
        {genesisMetadata && (
          Object.entries(genesisMetadata)
            .filter(([key]) => !["ID", "Name", "Description", "IPFS"].includes(key)) // Exclude unwanted keys
            .map(([key, value], index) => (
              <div key={index} className="trait-row">
                <p className="trait-label">{key.toUpperCase()}:</p>
                <p className="trait-value">{value}</p>
              </div>
            ))
        )}
  
        {carclubMetadata && (
          Object.entries(carclubMetadata)
            .filter(([key]) => !["ID", "Name", "Description", "IPFS", "Legendary Version"].includes(key)) // Exclude unwanted keys & "Legendary Version"
            .map(([key, value], index) => (
              <div key={index} className="trait-row">
                <p className="trait-label">{key.toUpperCase()}:</p>
                <p className="trait-value">{value}</p>
              </div>
            ))
        )}
      </div>
  
      {/* 🔄 Back Button */}
      <div className="backcard-button">
        <button className="button-ni" onClick={() => profileClick(props.data.name)} role="button">
          Back
        </button>
      </div>
    </div>
  );
  
  
  return (
    <div className='col-xxl-2 col-xl-3 col-md-4 col-sm-6 p-3'>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Card className="p-3" bg={"dark"}>
          <div className="card-content">
          <div className="card-name-row">
            <span className="name-text">
              {props.data.name.replace(/\d+/g, "").trim()}
            </span>
            {props.data.name.match(/\d+/) && (
              <span className="name-number">
                #{props.data.name.match(/\d+/)[0]}
              </span>
            )}
          </div>

            <div className="card-img-row">
              {isVideo ?
              <video width="100%" height="auto" className='media'controls>
                <source src={thumbnail} type="video/mp4" />
              </video>
              
              :
              <Card.Img variant="top" className='media' src={thumbnail} />
              }   
            </div>

            <div className="card-button-row">
              {isProcessing ? (
                // 🔥 Show the loader once when processing
                <div className="loading-spinner text-center">
                  <ThreeDots color="#f0f0f0" height={40} width={40} />
                </div>
              ) : (
                <div className="button-container">
                  <button className="button-ni" onClick={() => profileClick(props.data.name)}>
                    See Profile
                  </button>

                  {/* ✅ Hide Reveal button if the NFT is Staked */}
                  {!props.data.isStaked && props.data.thumbnail === "https://gateway.pinata.cloud/ipfs/QmPWCRKuzQqhwAjtC2RjUCQJZgcc42yBrhMHgA9YNJihjv/Placeholder.png" && (
                    <button className="button-ni" onClick={() => revealClick(props.data.nftId)}>
                      Reveal
                    </button>
                  )}

                  {/* 🔥 Conditional Staking & Unstaking */}
                  {props.data.isStaked ? (
                    <button className="button-ni" onClick={handleUnstakeClick}>
                      Unstake
                    </button>
                  ) : (
                    <button className="button-ni" onClick={handleStakeClick}>
                      Stake
                    </button>
                  )}
                </div>
              )}
            </div>




            {/* <Button variant="warning" className="mt-3" onClick={() => transferClick(props.data.id)}>
              Transfer to new Owner
            </Button> */}
          </div>
        </Card>

        <Card className="p-3" bg={"dark"}>
          {/* <div className="backcard-container">
            <div className="backcard-title">
              <h5 className="text-center font-style mt-4 mb-3">
                {props.data.name}
              </h5>
            </div> */}

            {backCard}

            {/* {props.data.traits && 
              <>
                
                <div className="trait-row">
                  <p className="text-white">
                    CAR PART  {props.data.traits["Car Part"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    PART RARITY <br /> {props.data.traits["Part Rarity"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    BACKGROUND <br /> {props.data.traits["Background"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    TIRE COMPOUND <br /> {props.data.traits["Tire Compound"]}
                  </p>
                </div>
              
                <div className="trait-row">
                  <p className="text-white">
                    TIRE TREAD <br /> {props.data.traits["Tire Tread"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    TIRE DESIGN <br /> {props.data.traits["Tire Design"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    RIM STYLE <br /> {props.data.traits["Rim Style"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    LOGO FORMAT <br /> {props.data.traits["Logo Format"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    LEGENDARY VERSION <br /> {props.data.traits["Legendary Version"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    STAKING % <br /> {props.data.traits["Staking %"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    SPEED <br /> {props.data.traits["Speed"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    BRAKING <br /> {props.data.traits["Braking"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    PASSING <br /> {props.data.traits["Passing"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    TURNING <br /> {props.data.traits["Turning"]}
                  </p>
                </div>
                
              </>
            }
            <div className="backcard-button">
              <button
                className="button-ni"
                onClick={() => profileClick(props.data.name)}
                role="button"
              >
                Back
                </button>
            </div> 
          </div>*/}
        </Card>
      </ReactCardFlip>
    </div>
  );
}
