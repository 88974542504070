export const claimRewards = `
import DriverzStakingXx from 0xf887ece39166906e
import FungibleToken from 0xf233dcee88fe0abe
import VroomToken from 0xf887ece39166906e

transaction {
    let tokenVault: &{FungibleToken.Receiver}

    prepare(signer: auth(Storage, BorrowValue, Capabilities) &Account) {
        // Borrow the owner's staking resource (we need a mutable reference because claimRewards is access(self))
        let stakingRef = signer.storage.borrow<&DriverzStakingXx.DriverzStake>(from: DriverzStakingXx.StakingStoragePath)
            ?? panic("Staking resource not found in storage!")
        
        // Borrow a reference to the owner's VroomToken vault that implements FungibleToken.Receiver.
        // Ensure the buyer's VroomToken vault exists or set it up
        if signer.storage.borrow<&VroomToken.Vault>(from: VroomToken.VaultStoragePath) == nil {
            let vroomVault <- VroomToken.createEmptyVault(vaultType: Type<@VroomToken.Vault>())
            signer.storage.save(<-vroomVault, to: VroomToken.VaultStoragePath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{FungibleToken.Receiver}>(VroomToken.VaultStoragePath),
                at: VroomToken.VaultReceiverPath
            )
        }
        self.tokenVault = signer.storage.borrow<&{FungibleToken.Receiver}>(from: VroomToken.VaultStoragePath)
            ?? panic("Could not borrow the buyer's VroomToken vault.")
        
        // Call the claimRewards function on the staking resource.
        // This returns a Vault containing the reward tokens.
        let rewardsVault <- stakingRef.claimRewards(recipient: self.tokenVault, owner: signer.address)
        
        // Deposit the rewards into the owner's VroomToken vault.
        self.tokenVault.deposit(from: <- rewardsVault)
    }

    execute {

    }
}
`