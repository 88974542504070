export const unstakeDriverz = `
import NonFungibleToken from 0x1d7e57aa55817448
import DriverzStakingXx from 0xf887ece39166906e
import CarClub from 0xf887ece39166906e
import Helmet from 0xf887ece39166906e
import Wheel from 0xf887ece39166906e
import Car from 0xf887ece39166906e
import DriverzNFT from 0xa039bd7d55a96c0c
import Tires from 0xf887ece39166906e

transaction(nftIds: [UInt64], nftTypes: [String]) {
    let address: Address
    let stakingRef: &DriverzStakingXx.DriverzStake
    let nfts: @{Int: {NonFungibleToken.NFT}}
    let collectionRefHelmet: &{Helmet.CollectionPublic}
    let collectionRefCarClub: &{CarClub.CollectionPublic}
    let collectionRefGenesis: &{DriverzNFT.CollectionPublic}
    let collectionRefCar: &{Car.CollectionPublic}
    let collectionRefWheel: &{Wheel.CollectionPublic}
    let collectionRefTires: &{Tires.TiresCollectionPublic}

    prepare(signer: auth(Storage, BorrowValue, Capabilities) &Account) {
        self.address = signer.address

        // Borrow the staking resource.
        self.stakingRef = signer.storage.borrow<&DriverzStakingXx.DriverzStake>(from: DriverzStakingXx.StakingStoragePath)
            ?? panic("Staking resource not found!")

        // Borrow the user's NFT collection (must implement NonFungibleToken.Receiver)
        self.collectionRefGenesis = signer.storage.borrow<&{DriverzNFT.CollectionPublic}>(from: /storage/DriverzNFTCollection)
            ?? panic("Could not borrow a reference to the Genesis NFT collection")

        self.collectionRefHelmet = signer.storage.borrow<&{Helmet.CollectionPublic}>(from: /storage/HelmetCollection)
            ?? panic("Could not borrow a reference to the Helmet NFT collection")

        self.collectionRefCarClub = signer.storage.borrow<&{CarClub.CollectionPublic}>(from: /storage/CarClubCollection)
            ?? panic("Could not borrow a reference to the Car Club NFT collection")

        self.collectionRefCar = signer.storage.borrow<&{Car.CollectionPublic}>(from: /storage/CarCollection)
            ?? panic("Could not borrow a reference to the Car NFT collection")

        self.collectionRefWheel = signer.storage.borrow<&{Wheel.CollectionPublic}>(from: /storage/WheelCollection)
            ?? panic("Could not borrow a reference to the Wheel NFT collection")

        self.collectionRefTires = signer.storage.borrow<&{Tires.TiresCollectionPublic}>(from: /storage/TiresCollection)
            ?? panic("Could not borrow a reference to the Tires NFT collection")

        self.nfts <- {}
    }

    execute {
        // Loop through the NFTs to unstake
        for i in [nftIds.length -1] {
            let nftID = nftIds[i]
            let nftType = nftTypes[i]

            // Unstake the NFT
            let nft <- self.stakingRef.unstakeNFT(nftID: nftID, nftType: nftType, owner: self.address)

            // Deposit back to the appropriate collection
            if nftType == "genesis" {
                self.collectionRefGenesis.deposit(token: <- nft)
            } else if nftType == "carclubcommon" || nftType == "carclubrare" || nftType == "carclubsponsor" || nftType == "carclublegendary" {
                self.collectionRefCarClub.deposit(token: <- nft)
            } else if nftType == "tires" {
                self.collectionRefTires.deposit(token: <- nft)
            } else if nftType == "wheels" {
                self.collectionRefWheel.deposit(token: <- nft)
            } else if nftType == "helmets" {
                self.collectionRefHelmet.deposit(token: <- nft)
            } else if nftType == "cars" {
                self.collectionRefCar.deposit(token: <- nft)
            } else {
                panic("Unsupported NFT type")
            }
        }

        destroy self.nfts
    }
}
`