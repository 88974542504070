export const getStakedNFTDetails = `
import DriverzStakingXx from 0xf887ece39166906e

access(all) fun main(owner: Address): [DriverzStakingXx.StakedNFTMetadata] {

    let account = getAccount(owner)

    let stakingRef = account.capabilities.borrow<&{DriverzStakingXx.DriverzStakePublic}>(DriverzStakingXx.StakingPublicPath)
        ?? panic("Could not borrow reference to TitStake public path")

    

    return stakingRef.getStakedNFTDetails(owner: owner)
}

`