export const getAllNFTs = `
import CarClub from 0xf887ece39166906e
import Helmet from 0xf887ece39166906e
import Wheel from 0xf887ece39166906e
import Car from 0xf887ece39166906e
import DriverzNFT from 0xa039bd7d55a96c0c
import MetadataViews from 0x1d7e57aa55817448
import ViewResolver from 0x1d7e57aa55817448
import NonFungibleToken from 0x1d7e57aa55817448


// Define a struct to hold the NFT ID, name, type, and thumbnail
access(all) struct NFTDetails {
    access(all) let nftId: UInt64
    access(all) let name: String
    access(all) let nftType: String
    access(all) let thumbnail: String

    init(nftId: UInt64, name: String, nftType: String, thumbnail: String) {
        self.nftId = nftId
        self.name = name
        self.nftType = nftType
        self.thumbnail = thumbnail
    }
}

access(all) fun main(accountAddress: Address): [NFTDetails] {
    // Get the account from the provided address
    let account = getAccount(accountAddress)

    // Prepare an array to hold the NFT details
    var nftDetails: [NFTDetails] = []

    // CAR CLUB DETAILS
    if let carClubCollectionRef = account.capabilities.borrow<&{NonFungibleToken.Collection, CarClub.CollectionPublic}>(/public/CarClubCollection){
        let carClubNFTIDs = carClubCollectionRef.getIDs()

        for carClubID in carClubNFTIDs {
            let carClubNFTRef = carClubCollectionRef.borrowCarClub(id: carClubID)
                ?? panic("Could not borrow the CarClub NFT with the given ID")

            // Construct the thumbnail IPFS link
            let ipfsLink = "https://gateway.pinata.cloud/ipfs/".concat(carClubNFTRef.image)

            nftDetails.append(NFTDetails(
                nftId: carClubNFTRef.id,
                name: carClubNFTRef.name,
                nftType: "Car Club",
                thumbnail: ipfsLink
            ))
        }
    } else {
        log("CarClub collection not found for this account.")
    }

    // HELMET DETAILS
    if let helmetCollectionRef = account.capabilities.borrow<&{NonFungibleToken.Collection, Helmet.CollectionPublic}>(/public/HelmetCollection) {
        let helmetNFTIDs = helmetCollectionRef.getIDs()

        for helmetID in helmetNFTIDs {
            let helmetNFTRef = helmetCollectionRef.borrowArt(id: helmetID)
                ?? panic("Could not borrow the Helmet NFT with the given ID")

            let ipfsLink = "https://gateway.pinata.cloud/ipfs/".concat(helmetNFTRef.ipfsLink)

            nftDetails.append(NFTDetails(
                nftId: helmetNFTRef.id,
                name: helmetNFTRef.name,
                nftType: "Helmet",
                thumbnail: ipfsLink  // Add thumbnail logic if needed
            ))
        }
    } else {
        log("Helmet collection not found for this account.")
    }

    // WHEEL DETAILS
    if let wheelCollectionRef = account.capabilities.borrow<&{NonFungibleToken.Collection, Wheel.CollectionPublic}>(/public/WheelCollection) {
        let wheelNFTIDs = wheelCollectionRef.getIDs()

        for wheelID in wheelNFTIDs {
            let wheelNFTRef = wheelCollectionRef.borrowArt(id: wheelID)
                ?? panic("Could not borrow the Wheel NFT with the given ID")

            let ipfsLink = "https://gateway.pinata.cloud/ipfs/".concat(wheelNFTRef.ipfsLink)

            nftDetails.append(NFTDetails(
                nftId: wheelNFTRef.id,
                name: wheelNFTRef.name,
                nftType: "Wheel",
                thumbnail: ipfsLink  // Add thumbnail logic if needed
            ))
        }
    } else {
        log("Wheel collection not found for this account.")
    }

    // CAR DETAILS
    if let carCollectionRef = account.capabilities.borrow<&{NonFungibleToken.Collection, Car.CollectionPublic}>(/public/CarCollection) {
        let carNFTIDs = carCollectionRef.getIDs()

        for carID in carNFTIDs {
            let carNFTRef = carCollectionRef.borrowArt(id: carID)
                ?? panic("Could not borrow the Car NFT with the given ID")

            let ipfsLink = "https://gateway.pinata.cloud/ipfs/".concat(carNFTRef.ipfsLink)

            nftDetails.append(NFTDetails(
                nftId: carNFTRef.id,
                name: carNFTRef.name,
                nftType: "Car",
                thumbnail: ipfsLink  // Add thumbnail logic if needed
            ))
        }
    } else {
        log("Car collection not found for this account.")
    }

    // DRIVERZ NFT DETAILS
    if let driverzCollectionRef = account.capabilities.borrow<&{NonFungibleToken.Collection, DriverzNFT.CollectionPublic}>(/public/DriverzNFTCollection) {
        let driverzNFTIDs = driverzCollectionRef.getIDs()

        for driverzID in driverzNFTIDs {
            let driverzNFTRef = driverzCollectionRef.borrowDriverzNFT(id: driverzID)
                ?? panic("Could not borrow the Driverz NFT with the given ID")

            let name = "Genesis ".concat(driverzNFTRef.id.toString())

            // Get the set ID and template ID from the NFT reference
            let setID = driverzNFTRef.setID
            let templateID = driverzNFTRef.templateID

            // Retrieve the template details using the setID and templateID
            let template = DriverzNFT.getTemplate(setID: setID, templateID: templateID)

            // Extract the display information based on whether the template is revealed
            let display = template.revealed() ? template.metadata!.display() : template.defaultDisplay



            nftDetails.append(NFTDetails(
                nftId: driverzNFTRef.id,
                name: name,
                nftType: "Genesis",
                thumbnail: display.thumbnail.uri()
            ))
        }
    } else {
        log("DriverzNFT collection not found for this account.")
    }
    // Return the array of NFT details
    return nftDetails
}

`
