export const stakeDriverz = `
import NonFungibleToken from 0x1d7e57aa55817448
import DriverzStakingXx from 0xf887ece39166906e
import VroomToken from 0xf887ece39166906e
import CarClub from 0xf887ece39166906e
import Helmet from 0xf887ece39166906e
import Wheel from 0xf887ece39166906e
import Car from 0xf887ece39166906e
import DriverzNFT from 0xa039bd7d55a96c0c
import Tires from 0xf887ece39166906e

transaction(nftIds: [UInt64], nftTypes: [String], nftNames: [String], thumbnails: [String]) {
let nfts: @{Int: {NonFungibleToken.NFT}}
let address: Address
let stakingRef: &{DriverzStakingXx.DriverzStakePublic}
let collectionRefHelmet: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
let collectionRefCarClub: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
let collectionRefGenesis: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
let collectionRefCar: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
let collectionRefWheel: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
let collectionRefTires: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}




    prepare(signer: auth(Storage, BorrowValue, Capabilities) &Account) {
        // Ensure the user has a staking resource; if not, create one.
        if signer.storage.borrow<&DriverzStakingXx.DriverzStake>(from: DriverzStakingXx.StakingStoragePath) == nil {
            let staking <- DriverzStakingXx.createDriverzStakingXx(owner: signer.address)
            signer.storage.save(<-staking, to: DriverzStakingXx.StakingStoragePath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{DriverzStakingXx.DriverzStakePublic}>(DriverzStakingXx.StakingStoragePath),
                at: DriverzStakingXx.StakingPublicPath
            )
        }

                // Borrow the staking resource.
        self.stakingRef = signer.storage.borrow<&DriverzStakingXx.DriverzStake>(from: DriverzStakingXx.StakingStoragePath)
            ?? panic("Staking resource not found!")



        // Check for HELMET    

        if signer.storage.borrow<&Helmet.Collection>(from: Helmet.CollectionStoragePath) == nil {
            let collection <- Helmet.createEmptyCollection(nftType: Type<@Helmet.NFT>())
            signer.storage.save(<-collection, to: Helmet.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Helmet.CollectionStoragePath),
                at: Helmet.CollectionPublicPath    
            )
        }
        self.collectionRefHelmet = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/HelmetCollection)
            ?? panic("Could not borrow a reference to the Helmet NFT collection")



        // Ensure the user has a Car Club NFT collection

        if signer.storage.borrow<&CarClub.Collection>(from: CarClub.CollectionStoragePath) == nil {
            let collection <- CarClub.createEmptyCollection(nftType: Type<@CarClub.NFT>())
            signer.storage.save(<-collection, to: CarClub.CollectionStoragePath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(CarClub.CollectionStoragePath),
                at: CarClub.CollectionPublicPath    
            )
        }

        self.collectionRefCarClub = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/CarClubCollection)
            ?? panic("Could not borrow a reference to the Car Club NFT collection")






        // Ensure the user has a Genesis NFT collection

        if signer.storage.borrow<&DriverzNFT.Collection>(from: DriverzNFT.CollectionStoragePath) == nil {
            let collection <- DriverzNFT.createEmptyCollection(nftType: Type<@DriverzNFT.NFT>())
            signer.storage.save(<-collection, to: DriverzNFT.CollectionStoragePath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(DriverzNFT.CollectionStoragePath),
                at: DriverzNFT.CollectionPublicPath    
            )
        }

        self.collectionRefGenesis = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/DriverzNFTCollection)
            ?? panic("Could not borrow a reference to the DriverzNFT collection")



        // Ensure the user has a Car NFT collection

        if signer.storage.borrow<&Car.Collection>(from: Car.CollectionStoragePath) == nil {
            let collection <- Car.createEmptyCollection(nftType: Type<@Car.NFT>())
            signer.storage.save(<-collection, to: Car.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Car.CollectionStoragePath),
                at: Car.CollectionPublicPath    
            )
        }

        self.collectionRefCar = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/CarCollection)
            ?? panic("Could not borrow a reference to the Car NFT collection")




        // Ensure the user has a Wheel NFT collection

        if signer.storage.borrow<&Wheel.Collection>(from: Wheel.CollectionStoragePath) == nil {
            let collection <- Wheel.createEmptyCollection(nftType: Type<@Wheel.NFT>())
            signer.storage.save(<-collection, to: Wheel.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Wheel.CollectionStoragePath),
                at: Wheel.CollectionPublicPath    
            )
        }

        self.collectionRefWheel = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/WheelCollection)
            ?? panic("Could not borrow a reference to the Wheel NFT collection")




        // Ensure the user has a Tires NFT collection

        if signer.storage.borrow<&Tires.Collection>(from: Tires.CollectionStoragePath) == nil {
            let collection <- Tires.createEmptyCollection(nftType: Type<@Tires.NFT>())
            signer.storage.save(<-collection, to: Tires.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Tires.CollectionStoragePath),
                at: Tires.CollectionPublicPath    
            )
        }

        self.collectionRefTires = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/TiresCollection)
            ?? panic("Could not borrow a reference to the Tires NFT collection")


        self.address = signer.address
        self.nfts <- {}

        // Ensure that an assets dictionary exists for this owner.
        if !DriverzStakingXx.hasAssets(owner: signer.address) {
            DriverzStakingXx.createAssetsDictionary(owner: signer.address)
        }




        // Ensure all input arrays are of equal length.
        if nftIds.length != nftTypes.length || nftIds.length != nftNames.length {
            panic("Input arrays must be of equal length")
        }

        for i in [nftTypes.length -1] {
            // if the type is pages, the we'll use the pages collection ref and if the type is courtiers, then we'll use the courtiers collection ref
            // Withdraw the NFT from the user's collection.
            if nftTypes[i] == "genesis" {
                self.nfts[i] <-! self.collectionRefGenesis.withdraw(withdrawID: nftIds[i])
            } else if nftTypes[i] == "carclubcommon" {
                self.nfts[i] <-! self.collectionRefCarClub.withdraw(withdrawID: nftIds[i]) 
            } else if nftTypes[i] == "carclubrare" {
                self.nfts[i] <-! self.collectionRefCarClub.withdraw(withdrawID: nftIds[i]) 
            } else if nftTypes[i] == "carclubsponsor" {
                self.nfts[i] <-! self.collectionRefCarClub.withdraw(withdrawID: nftIds[i]) 
            } else if nftTypes[i] == "carclublegendary" {
                self.nfts[i] <-! self.collectionRefCarClub.withdraw(withdrawID: nftIds[i]) 
            } else if nftTypes[i] == "tires" {
                self.nfts[i] <-! self.collectionRefTires.withdraw(withdrawID: nftIds[i]) 
            } else if nftTypes[i] == "wheels" {
                self.nfts[i] <-! self.collectionRefWheel.withdraw(withdrawID: nftIds[i]) 
            } else if nftTypes[i] == "helmets" {
                self.nfts[i] <-! self.collectionRefHelmet.withdraw(withdrawID: nftIds[i]) 
            } else if nftTypes[i] == "cars" {
                self.nfts[i] <-! self.collectionRefCar.withdraw(withdrawID: nftIds[i]) 
            } else {
                panic("Unsupported NFT type") 
            }
        }



        

    }

    execute {

        // Loop over each NFT to stake.
        for i in [nftIds.length - 1] {
            let id = nftIds[i]
            let type = nftTypes[i]
            let name = nftNames[i]
            let thumbnail = thumbnails[i]

            let nft <- self.nfts.remove(key: i)
                ?? panic("NFT not found in dictionary")

                // Stake the NFT with its level.
                self.stakingRef.stakeNFT(
                    nft: <- nft,
                    nftID: id,
                    nftType: type,
                    nftName: name,
                    owner: self.address,
                    thumbnail: thumbnail
                )

        }

        destroy self.nfts
    }
}
    `
