export const initAll = `

//Mainnet

import FungibleToken from 0xf233dcee88fe0abe
import DriverzNFT from 0xa039bd7d55a96c0c
import VroomToken from 0xf887ece39166906e
import CarClub from 0xf887ece39166906e
import MetadataViews from 0x1d7e57aa55817448
import NonFungibleToken from 0x1d7e57aa55817448
import RaffleTickets from 0xf887ece39166906e
import Helmet from 0xf887ece39166906e
import Wheel from 0xf887ece39166906e
import Car from 0xf887ece39166906e
import Tires from 0xf887ece39166906e
import DriverzStakingXx from 0xf887ece39166906e

transaction {
    prepare(signer: auth(Storage, Capabilities) &Account) {
        if signer.storage.borrow<&DriverzStakingXx.DriverzStake>(from: DriverzStakingXx.StakingStoragePath) == nil {
            let staking <- DriverzStakingXx.createDriverzStakingXx(owner: signer.address)
            signer.storage.save(<-staking, to: DriverzStakingXx.StakingStoragePath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{DriverzStakingXx.DriverzStakePublic}>(DriverzStakingXx.StakingStoragePath),
                at: DriverzStakingXx.StakingPublicPath
            )
        }

        if signer.storage.borrow<&CarClub.Collection>(from: CarClub.CollectionStoragePath) == nil {
            let collection <- CarClub.createEmptyCollection(nftType: Type<@CarClub.NFT>())
            signer.storage.save(<-collection, to: CarClub.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(CarClub.CollectionStoragePath),
                at: CarClub.CollectionPublicPath    
            )
        }

        if signer.storage.borrow<&Tires.Collection>(from: Tires.CollectionStoragePath) == nil {
            let collection <- Tires.createEmptyCollection(nftType: Type<@Tires.NFT>())
            signer.storage.save(<-collection, to: Tires.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Tires.CollectionStoragePath),
                at: Tires.CollectionPublicPath    
            )
        }

        if signer.storage.borrow<&Wheel.Collection>(from: Wheel.CollectionStoragePath) == nil {
            let collection <- Wheel.createEmptyCollection(nftType: Type<@Wheel.NFT>())
            signer.storage.save(<-collection, to: Wheel.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Wheel.CollectionStoragePath),
                at: Wheel.CollectionPublicPath    
            )
        }

        if signer.storage.borrow<&Helmet.Collection>(from: Helmet.CollectionStoragePath) == nil {
            let collection <- Helmet.createEmptyCollection(nftType: Type<@Helmet.NFT>())
            signer.storage.save(<-collection, to: Helmet.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Helmet.CollectionStoragePath),
                at: Helmet.CollectionPublicPath    
            )
        }

        if signer.storage.borrow<&Car.Collection>(from: Car.CollectionStoragePath) == nil {
            let collection <- Car.createEmptyCollection(nftType: Type<@Car.NFT>())
            signer.storage.save(<-collection, to: Car.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(Car.CollectionStoragePath),
                at: Car.CollectionPublicPath    
            )
        }

        if signer.storage.borrow<&RaffleTickets.Collection>(from: RaffleTickets.CollectionStoragePath) == nil {
            let collection <- RaffleTickets.createEmptyCollection(nftType: Type<@RaffleTickets.NFT>())
            signer.storage.save(<-collection, to: RaffleTickets.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(RaffleTickets.CollectionStoragePath),
                at: RaffleTickets.CollectionPublicPath    
            )
        }

        if signer.storage.borrow<&DriverzNFT.Collection>(from: DriverzNFT.CollectionStoragePath) == nil {
            let collection <- DriverzNFT.createEmptyCollection(nftType: Type<@DriverzNFT.NFT>())
            signer.storage.save(<-collection, to: DriverzNFT.CollectionStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(DriverzNFT.CollectionStoragePath),
                at: DriverzNFT.CollectionPublicPath    
            )
        }


        if signer.storage.borrow<&VroomToken.Vault>(from: VroomToken.VaultStoragePath) == nil {
            let vault <- VroomToken.createEmptyVault(vaultType: Type<@VroomToken.Vault>())
            signer.storage.save(<-vault, to: VroomToken.VaultStoragePath)

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{FungibleToken.Receiver}>(VroomToken.VaultStoragePath),
                at: VroomToken.VaultReceiverPath
            )

            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{FungibleToken.Balance}>(VroomToken.VaultStoragePath),
                at: VroomToken.VaultBalancePath
            )
        }
    }
}
    
`
